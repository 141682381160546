.pus-compose {
  width: 40%;
  position: fixed;
  min-height: 200px;
  bottom: 10px;
  right: 10px;

  background-color: #fff;
  border: 1px solid #f1f1f1;

  display: flex;
  flex-direction: column;
}

.pus-compose-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.pus-compose input,
.pus-compose textarea {
  width: calc(100% - 20px);
  margin: 10px;
}

.pus-compose-right {
  margin-left: auto;
}

.public-DraftEditor-content {
  max-height: 160px;
  min-height: 160px;
  overflow: auto;
}

.compose-header .compose-participant-field .react-select__indicators div span {
  border-radius: 1em;
  display: inline-block;
  position: relative;
  right: 40px;
  padding: 0;
  margin-left: 1em;
  height: 1em;
  vertical-align: top;
  width: 1em;
}
.compose-header .compose-participant-field select {
  padding-right: 25px;
}

.pus-right-padding {
  padding-right: 4.5rem !important;
}
.custom-compose-header {
  position: relative;
  z-index: 99;
}

.custom-select-control {
  position: relative;
  height: auto !important;
  /* padding-right: 65px; */
}

.react-select__control {
  border: 0 !important;
  outline: none !important;
  padding: 0;
  box-shadow: none !important;
  background-color: var(--compose-body-bg-color) !important;
}
.react-select__control input {
  color: var(--form-control-text-color) !important;
}

.react-select__value-container {
  padding-left: 0 !important;
}
.react-select__multi-value {
  background-color: var(--react-select-multi-value-bg-color) !important;
}
.react-select__indicators {
  display: block !important;
  margin-right: 20px;
  float: right;
}
.react-select__menu {
  background-color: var(--react-select-menu-bg-color) !important;
}

.custom-action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--action-link-text-color);
  width: 32px;
  height: 32px;
  font-size: 18px;
  border-radius: 4px;
  padding: 0 5px;
  margin-left: 5px;
  border: 0;
  background-color: transparent;

  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.custom-action-btn:hover {
  background-color: var(--action-link-hover-bg-color);
}

body {
  --ck-z-default: 9999;
  --ck-z-modal: 9999;
}

.pus-compose-editor {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: stretch;
  min-height: 300px;
}

.compose-form {
  background-color: #ffffff;
}

@media (max-width: 767.98px) {
  .pus-right-padding {
    padding-right: 0 !important;
  }
  .react-select__indicators {
    margin-right: 10px;
  }
  .react-select__menu {
    width: 100% !important;
    margin-left: -65px !important;
    z-index: 11 !important;
  }
}

@media (max-width: 575.98px) {
  .compose-inner .compose-action {
    justify-content: flex-start;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.pus-align-right {
  margin-left: auto;
}

.pus-progress {
  transition: width 2s;
}

.compose__dropzone {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px dashed #d1d1d1;
  z-index: 2;
  border-radius: 2px;
  pointer-events: none;
  font-weight: bold;
}

.compose__dropzone--hidden {
  display: none;
}

.tox-tinymce {
  width: 100%;
  border: 0 !important;
}
.tox-toolbar__primary {
  background: none !important;
}

button[aria-label="hizalanma"]::after,
button[aria-label="Alignment"]::after {
  content: "›";
  display: inline-block;
  margin: 0 0 0 0.1rem;
  transition: transform 0.2s ease-in-out;
  font-size: 1.5rem;
  transform: rotate(90deg);
  color: #90969e;
}

.checkmark {
  flex: 0 !important;
  margin-right: 7px;
  min-width: 15px;
}

.btn-send {
  border-right: 2px solid #4242ff;
}

.sign-item-button:disabled {
  color: var(--primary-text-color) !important;
}

.px-schedule {
  padding: 10px 85px;
}

@media (max-width: 575.98px) {
  .px-schedule {
    padding: 10px 35px;
  }

  .px-title-schedule {
    padding: 0px 45px;
  }
}
