.my-tippy-arrow,
.my-tippy-arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
}

.my-tippy-arrow::before {
  content: "";
  transform: rotate(45deg);
  background: var(--white-space-bg-color);
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.my-tippy-tooltip {
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;

  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);

  color: #727e8c;
  text-align: left;
  background-color: var(--white-space-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.my-tippy-tooltip[data-placement^="top"] > .my-tippy-arrow {
  bottom: -8px;
}

.my-tippy-tooltip[data-placement^="bottom"] > .my-tippy-arrow {
  top: -8px;
}

.my-tippy-tooltip[data-placement^="left"] > .my-tippy-arrow {
  right: -8px;
}

.my-tippy-tooltip[data-placement^="right"] > .my-tippy-arrow {
  left: -8px;
}
